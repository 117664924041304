//CHEESECAKE THEME//
$dark-red: #4C1B1B;
$red:#B9121B;
$darkerbeige: #BD8D46;
$beige: #F6E497;
$offwhite: #FCFAE1;

//BLUE THEME//
//$dark-red: #183152;
//$red: #375D81;
//$darkerbeige: #ABC8E2;
//$beige: #C4D7ED;
//$offwhite: #E1E6FA;

$tablet : 641px;
$desktop: 1025px;
