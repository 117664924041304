@import "variables";
/*--------------------------------------------------------------
If no grid support, limit width to 50em and center align
--------------------------------------------------------------*/
@supports not (display: grid) {
  .page-title,
  .main-content,
  .sidebar {
    max-width: 50em;
    margin: 0 auto;
  }
}

/*--------------------------------------------------------------
CSS Grid
--------------------------------------------------------------*/

@supports (display: grid) {
  @media screen and (min-width: $tablet) {

    .App {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto 1fr 3fr;
      grid-template-areas:
              "content-head content-head"
              "main header"
              "main sidebar"
              "footer footer";
    }

    .App-header {
      grid-area: header;
    }

    .App-title {
      grid-area: content-head;
    }

    .App-content {
      grid-area: main;
    }

    .App-sidebar {
      grid-area: sidebar;
    }

    .App-footer {
      grid-area: footer;
    }

  }

  @media screen and (min-width: $desktop) {
    .App {
      grid-template-columns: 2fr 1fr 1fr;
      grid-template-areas:
              "content-head content-head content-head"
              "main header header"
              "main sidebar footer";
    }
  }
}
